.tabContainer {
  width: 100%;
  max-width: 500px;
  padding-inline: 2rem;
}

.tabSelect {
  display: flex;
  justify-content: stretch;
  gap: 0.125rem;
  padding: 0.125rem;
  margin: 0 auto 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.tabSelect > * {
  width: 100%;
}

.tabContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 0.5rem;
}

.groupStyle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.groupBadgeStyles {
  background-color: #EBECF0;
  border-radius: 2em;
  color: #172B4D;
  display: inline-block;
  font-size: 12;
  font-weight: normal;
  line-height: 1;
  min-width: 1;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
}
